import React from 'react'
import Styles from '../../pages/Styles.module.css';
import { user} from '../../functions/O3developer';
import Bubbles from '../../components/Bubbles';
// import ScaleLoader from "react-spinners/ScaleLoader";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

function TopCard(props) {
  return (
    <div>        
        <div className={"card card-fixed bg-6 " + Styles.data_card}>
		<div className={"card-top p-3 mt-1 " + Styles.pt_65}>
			<div className="d-flex" style={{ alignItems: "flex-end" }}>
				<div>
				<h6 className={"font-700 color-white font-14 pt-1 " + Styles.txt_font_color }> 
				{ props.wallet === "" ? <SkeletonTheme height={18} width={70}> <Skeleton count={1} /> </SkeletonTheme> : "Hello 👋" } </h6>
          <h5 className={"font-20 mb-1 color-white pt-1 " + Styles.txt_font_color + ' ' + Styles.topCardFont }>
		  { props.wallet === "" ? <SkeletonTheme height={20} width={110}> <Skeleton count={1} /> </SkeletonTheme> : user().lastname }</h5>
        </div>
				{/* Vertical line */}
				<div className="vertical-line"></div>

				<div className={"ms-auto text-center " + Styles.left_border }>				
					<h6 className="font-700 color-white font-14 pt-1 mb-0">
					{ props.wallet === "" ? <SkeletonTheme height={18} width={100}> <Skeleton count={1} /> </SkeletonTheme> : "Wallet Balance" } </h6>

					<h5 className={"font-20 mb-1 color-white pt-1 " + Styles.txt_font_color + ' ' + Styles.topCardFont }>
              { props.wallet !== "" ? "₦" : null }{props.wallet !== "" ? Math.round(props.wallet * 100) / 100 : null}
			  { props.wallet === "" ? <SkeletonTheme height={20} width={70}> <Skeleton count={1} /> </SkeletonTheme> : null }
			  </h5>
				</div>

			</div>

            <Bubbles/>

		</div>


		<div className="card-overlay bg-gradient" style={{ borderBottomLeftRadius: "50px", borderBottomRightRadius: "50px" }}></div>
		<div className="card-overlay bg-black opacity-75" style={{ borderBottomLeftRadius: "50px", borderBottomRightRadius: "50px" }}></div>
    </div>


    </div>
  )
}

export default TopCard