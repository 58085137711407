import AOS from 'aos';
import 'aos/dist/aos.css';
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import PageTopBar from '../../components/PageTopBar'


function Card(props) {

  useEffect(() => AOS.init(), []);

  return (
    <Link to={props.link} data-aos={props.aos} data-aos-duration="1500" className="card card-style py-4 mx-0 mb-3">
      <div className="d-flex px-3">
      <div className="align-self-center">
      <img src={props.img} height="60" width="60" className="rounded-xl" />
      </div>
      <div className="ps-2 align-self-center" style={{ lineHeight: "20px" }}>
      <h4 className="mb-1"> {props.title} </h4>
      <span className="font-12 color-black opacity-70" > {props.text} </span>
      </div>
      <div className="ms-auto align-self-center">
      <i className="fa fa-arrow-right opacity-30 color-theme"></i>
      </div>
      </div>
    </Link>
  )
}




function FundWallet() {
  
  return (
    <div>

      
    <div className='header header-fixed header-logo-center header-auto-show'> </div>
      
    <PageTopBar title="Fund Wallet"/>


    <div className="page-title-clear"></div>
    <div className="page-content">
    <div className="content mb-0 mt-0">

    {/* Instant Transfer */}
    <Card link="/fund-wallet/virtual-account" img="/dashboard/images/monnify_logo.png"
      text="Get funded instantly after making transfer to the account number provided."
      title="Monnify Funding" aos="fade-up"
    />

<Card link="/fund-wallet/providus" img="/dashboard/images/providus.jpg"
      text="Get funded instantly after making transfer to the account number provided."
      title="Providus Funding" aos="fade-up"
    />

    {/* ATM Card */}
    <Card link="/fund-wallet/atm" img="/dashboard/images/credit-card_.png"
      text="Deposit funds into your wallet with your debit card or bank transfer."
      title="ATM Card Funding" aos="zoom-out-left"
    />


    {/* Manual Funding */}
    <Card link="/fund-wallet/manual-funding" img="/dashboard/images/wallet-icon.png"
      text="Request for your wallet to be funded if you have made payment into our bank."
      title="Manual Funding" aos="zoom-in-right"
    />


    {/* Coupon Code */}
    <Card link="/fund-wallet/coupon" img="/dashboard/images/Coupons.jpg"
      text="Fund your wallet with coupon code generated for you via our system."
      title="Coupon Code" aos="flip-down"
    />



    {/* Coupon Code */}
    {/* <Card link="/airtime-cash" img="/dashboard/images/naira.png"
      text="Fund your wallet with airtime [We accept both airtime transfer and recharge pin]."
      title="Airtime Funding"
    /> */}





    </div>
    </div>




    </div>
  )
}

export default FundWallet