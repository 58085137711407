import React, { useEffect, useState } from 'react';
import Styles from './Login.module.css';
import { useNavigate, useSearchParams   } from "react-router-dom";
import axios from 'axios';
import { auth, toaster, baseURL, siteLogo } from '../functions/O3developer';
import PulseLoader from "react-spinners/PulseLoader";
import { ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';
import error from '../json/error.json';

function Register() {
  const [searchParams] = useSearchParams();
  const referral = searchParams.get("ref") !== null ? searchParams.get("ref") : '';
  const [registerState, setRegisterState] = useState({
      firstname: '',
      lastname: '',
      email: '',
      number: '',      
      username: '',
      password: '',
      referral: '',
      confirm_password: '',
  });
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const formHandler = async (event) => {
      event.preventDefault();
      if (!loading) {
      if (registerState.firstname == "")  return toaster("error", "The firstname field cannot be empty");
      if (registerState.lastname == "")  return toaster("error", "The lastname field cannot be empty");
      if (registerState.username == "")  return toaster("error", "The username field cannot be empty");
      if (registerState.email == "")  return toaster("error", "The email field cannot be empty");
      if (registerState.number == "")  return toaster("error", "The number field cannot be empty");
      if (registerState.number.length !== 11)  return toaster("error", "Please input a valid phone number");
      if (registerState.password == "")  return toaster("error", "The password field cannot be empty");
      if (registerState.username.length < 2)  return toaster("error", "Invalid username supplied ");
      if (registerState.password.length < 2)  return toaster("error", "Invalid password supplied ");
      setLoading(true);
      axios.defaults.headers.common = {'Content-Type': 'application/json'}
      await axios.post(baseURL+"/user/register",  registerState).then((response) => {

        if (response.data.code !== 200 && response.data.status == "failed") {
          setLoading(false);
          return toaster("error", response.data.message);
        } else if (response.data.code == 200 && response.data.status == "success") {
          setLoading(false);
          return navigate("/login", { 
            state: { message: 'Your registration was successful. We are glad to have you 🤝' }  
          });
        } else {
          setLoading(false);
        }

        }).catch((e)=>{
            setLoading(false);
            alert(error.whoops)
            // return e;
        })
      }
    }

      useEffect(() => { if (auth() == true) {
        return navigate("/")
      } else {        
        setRegisterState({...registerState, referral})
      }
     } , []);

  return (
    <div>


<ToastContainer /> 


<div className='pt-2 my-5'>
<div className="container">
    <div className="row justify-content-center">
      <div className="col-md-8 col-lg-6 col-xl-5" style={{ padding: 0 }}>


<div className={"card card-style mt-lg-0 " + Styles.login_card }>


<div className="content">


     {/* <p className="font-600 color-highlight mb-n1">Let's start</p> */}
<div>
<h1 className="font-25 float-start">Sign Up</h1>
<img src={siteLogo} style={{  width: "50px" }} className="float-end"/>
</div>


<br/>
<p style={{ marginBottom: "20px", paddingTop: "16px" }}>
Register an account to access our services.
</p>





<form method='post' onSubmit={formHandler}>


<div className="row gx-2 mb-0">

<div className="input-style has-borders has-icon validate-field mb-4 col-lg-6">
<i className="fas fa-arrow-right"></i>
<input type="text" value={registerState.firstname} onChange={e => setRegisterState({...registerState, firstname: e.target.value})} className="form-control validate-name shadow-bg-m shadow-s" placeholder="Enter your firstname" autoComplete="off" required />
<label htmlFor="form1" className="color-highlight">Firstname</label>
<i className="fa fa-times disabled invalid color-red-dark"></i>
<i className="fa fa-check disabled valid color-green-dark"></i>
<em>(required)</em>
</div>


<div className="input-style has-borders has-icon validate-field mb-4 col-lg-6">
<i className="fas fa-arrow-right"></i>
<input type="text" value={registerState.lastname} onChange={e => setRegisterState({...registerState, lastname: e.target.value})} className="form-control validate-name shadow-bg-m shadow-s" placeholder="Enter your lastname" autoComplete="off" required />
<label htmlFor="form1" className="color-highlight">Lastname</label>
<i className="fa fa-times disabled invalid color-red-dark"></i>
<i className="fa fa-check disabled valid color-green-dark"></i>
<em>(required)</em>
</div>




<div className="input-style has-borders has-icon validate-field mb-4 col-lg-6">
<i className="fas fa-user"></i>
<input type="text" value={registerState.username} onChange={e => setRegisterState({...registerState, username: e.target.value})} className="form-control validate-name shadow-bg-m shadow-s" placeholder="Enter your username" autoComplete="off" required />
<label htmlFor="form1" className="color-highlight">Username</label>
<i className="fa fa-times disabled invalid color-red-dark"></i>
<i className="fa fa-check disabled valid color-green-dark"></i>
<em>(required)</em>
</div>



<div className="input-style has-borders has-icon validate-field mb-4 col-lg-6">
<i className="fas fa-at"></i>
<input type="email" value={registerState.email} onChange={e => setRegisterState({...registerState, email: e.target.value})} className="form-control validate-name shadow-bg-m shadow-s" placeholder="Enter your email address" autoComplete="off" required />
<label htmlFor="form1" className="color-highlight">Email</label>
<i className="fa fa-times disabled invalid color-red-dark"></i>
<i className="fa fa-check disabled valid color-green-dark"></i>
<em>(required)</em>
</div>



<div className="input-style has-borders has-icon validate-field mb-4 col-lg-6">
<i className="fas fa-phone"></i>
<input type="number" value={registerState.number} onChange={e => setRegisterState({...registerState, number: e.target.value})} className="form-control validate-name shadow-bg-m shadow-s" placeholder="Enter your phone number" autoComplete="off" required />
<label htmlFor="form1" className="color-highlight">Number</label>
<i className="fa fa-times disabled invalid color-red-dark"></i>
<i className="fa fa-check disabled valid color-green-dark"></i>
<em>(required)</em>
</div>



<div className="input-style has-borders has-icon validate-field mb-4 col-lg-6">
<i className="fa fa-key"></i>
<input type="password" value={registerState.password} onChange={e => setRegisterState({...registerState, password: e.target.value})} id="password" className="form-control validate-password shadow-bg-m shadow-s" placeholder="Enter your password" autoComplete="off" required />
<label htmlFor="form1" className="color-highlight">Password</label>
<i className="fa fa-times disabled invalid color-red-dark"></i>
<i className="fa fa-check disabled valid color-green-dark"></i>
<em>(required)</em>
</div>



<div className="input-style has-borders has-icon validate-field mb-4">
<i className="fa fa-key"></i>
<input type="password" value={registerState.confirm_password} onChange={e => setRegisterState({...registerState, confirm_password: e.target.value})} id="confirm_password" className="form-control validate-password shadow-bg-m shadow-s" placeholder="Confirm your password (Re-type)" autoComplete="off" required />
<label htmlFor="form1" className="color-highlight">Password</label>
<i className="fa fa-times disabled invalid color-red-dark"></i>
<i className="fa fa-check disabled valid color-green-dark"></i>
<em>(required)</em>
</div>



</div>




<button disabled={loading}
className={"btn btn-full rounded-sm btn-block shadow-l mb-2 mt-2 shadow-bg-m shadow-s " + Styles.login_btn }
style={{background: loading ? "linear-gradient(to right, rgb(167 2 58 / 75%), rgb(32 32 76 / 78%), rgb(32 32 76 / 76%))": "linear-gradient(to right, #a7023af7, #20204ceb, #20204c)" }}
>
  { loading ? <PulseLoader color={"white"} loading={true} size={8} /> : '' }
 { loading ? '' : 'Register' }


</button>

</form>




<div className="row pt-2 mb-2">
<div className="col-9 text-start">
<a href="#" className="color-highlight">Already have an account?</a>
</div>
 <div className="col-3 text-end">
<Link to='/login' className="color-highlight"> Sign In </Link>
</div>
</div>


          </div>
        </div>
      </div>
    </div>
  </div>
</div>


    </div>
  )
}

export default Register
