import axios from 'axios';
import { useEffect, useReducer, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { baseURL, siteLogo, storage, toaster, user } from '../../functions/O3developer';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import PageTopBar from '../../components/PageTopBar';


function Verification () {

    const navigate = useNavigate();

    useEffect(() => { }, []);

  return (

<div>
                
        <PageTopBar title="Verification" />

        <div className="page-title-clear"></div>
        <div className="page-content">

        <div className="card card-style" style={{ borderTopLeftRadius: "0px", borderBottomRightRadius: "0px" }}>
        <div className="content">

        <span className="shadow-bg shadow-bg-m shadow-s" style={{float: 'right', width: '55px', height: '55px', padding: '8px', borderRadius: '50%'}}>
            <img src="/dashboard/images/fingerprint-verified.jpeg" className="text-center" width="39" style={{  borderRadius: "50%"  }} />
        </span>
            
        <p style={{ color: "#393636", marginBottom: '5px' }} >
            Kindly be aware that the Central Bank of Nigeria mandates the linkage of all virtual accounts with your BVN (Bank Verification Number) or NIN (National Identification Number). 
            It is imperative to comply with this requirement to ensure uninterrupted use of the virtual account provided for your funding activities.
        </p> 
 

        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
            
            <Link to={"/kyc/bvn-link"} type="button"  className="btn btn-success btn-3d btn-sm btn-m font-600 shadow-bg shadow-bg-m shadow-s ">
                Link Now <i className="fas fa-check-circle"></i>
            </Link>

            <Link to={"/fund-wallet/dynamic-account"} type="button"  className="btn btn-danger btn-3d btn-sm btn-m font-600 shadow-bg shadow-bg-m shadow-s ">
                Fund wallet without linking BVN / NIN
            </Link>
            
        </div>


        </div>
        </div>





        <ToastContainer />




        </div>



</div>


  )
}

export default Verification
